import { useState } from 'react';
import { useFileUpload, useValidation } from '../../../hooks';
import { validationsAds, validationsBrandedObjects } from './validations';
import { AdType } from '../../../global/consts';
import { MultiSelect } from '../../multi-select/multi-select';
import { AdData, AdsData, BrandedObjectData, Dispatcher, Refetch } from '../../../global/interfaces';
import { CampaignFormData } from '../create-campaign-modal/create-campaign-modal';
import { useReneMutation } from '../../../hooks/useReneMutation';
import { UPSERT_BRANDED_OBJECT_MUTATION, UPSERT_BULK_AD_MUTATION } from '../../../global/gql/mutations';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Spinner from '../../spinner/spinner';
import FileUpload from '../../file-upload/file-upload';
import RadioButtons from '../../radio/radio-buttons';
import InlineFileUpload from '../../inline-file-upload/inline-file-upload';

import './create-ad-modal.scss';

const ImageVideoAds = ({
  adCampaignId,
  handleModalClose,
  refetch,
  setFormData,
}: {
  adCampaignId: string;
  handleModalClose: () => void;
  refetch: Refetch<{ Ads: { ads: AdData[] } }>;
  setFormData: Dispatcher<CampaignFormData>;
}) => {
  const uploadFile = useFileUpload();
  const { errors, isFormInvalid } = useValidation(validationsAds);
  const [loading, setLoading] = useState(false);
  // const [filesType, setFilesType] = useState<'single-ad-with-variants' | 'multiple-ads'>('single-ad-with-variants');
  const [files, setFiles] = useState<File[]>([]);
  const [upsertAd] = useReneMutation(UPSERT_BULK_AD_MUTATION, {
    onCompleted: async (data: { BulkInsertAd: { ads: AdsData } }) => {
      const promises: Promise<Response | void>[] = [];
      const type = data.BulkInsertAd.ads.items[0].adType === AdType.BANNER ? 'banner' : 'video';
      const ads = data.BulkInsertAd.ads.items;
      if (files) {
        ads.forEach((ad, i) => {
          promises.push(uploadFile(ad[type].uploadUrl, files[i]));
        });
        Promise.all(promises).then(() => {
          refetch().then(() => {
            setLoading(false);
            handleModalClose();
            setFormData((prev) => ({
              ...prev,
              selectedAdsIds: [...prev.selectedAdsIds, ...ads.map((ad) => ad.adId)],
              selectedAds: [...ads, ...prev.selectedAds],
            }));
          });
        });
      }
    },
  });

  const handleUpsertAd = () => {
    if (isFormInvalid({ files: files })) return;
    setLoading(true);
    const hasImages = files.some((file) => file.type.startsWith('image/'));
    const variables = {
      adCampaignId,
      ads: files.map((file) => ({
        name: file.name,
        banner: hasImages ? { extension: file?.type.split('/')[1] } : undefined,
        video: hasImages ? undefined : { extension: file?.type.split('/')[1] },
      })),
    };

    upsertAd({ variables });
  };

  return (
    <div className="image-video-ads">
      <FileUpload
        label="Name"
        setFiles={(files: File[]) => setFiles(files)}
        multiple
        newStyle
        description="Drag and drop image/video or click here to select (jpg, png, <5mb)/(mp4, <10mb)"
        errorMessage={errors?.files}
      />
      <p className="image-video-ads__description">
        <Icon name="alert" size={16} /> Ads will be named from the filename; you can rename them later, if needed.
      </p>
      <div className="image-video-ads__files">
        {/* <RadioButtons
          newStyle
          label="Multiple files uploaded - what are they?"
          options={[
            { label: 'A single ad with variants', value: 'single-ad-with-variants' },
            { label: 'Multiple ads', value: 'multiple-ads' },
          ]}
          selectedValue={filesType}
          setSelectedValue={setFilesType}
        /> */}
        <div className="image-video-ads__files-list">
          {files &&
            Object.values(files).map((file, i) => (
              <div key={i}>
                <div>
                  <Icon name="file" />
                  <p>{file.name}</p>
                </div>
                <p>{(file.size / (1024 * 1024)).toFixed(2)} Mb</p>
              </div>
            ))}
        </div>
      </div>
      <div className="create-ad-modal__footer">
        <button type="button" className="btn-neutral-ghost" onClick={handleModalClose}>
          Cancel
        </button>
        <button type="button" className="btn-primary-solid" onClick={handleUpsertAd}>
          {loading ? <Spinner size="sm" /> : 'Create Ad'}
        </button>
      </div>
    </div>
  );
};

const BrandedObjectAds = ({
  refetch,
  adCampaignId,
  handleModalClose,
  setFormData,
}: {
  refetch: Refetch<{
    Ads: {
      ads: AdData[];
      brandedObjects: BrandedObjectData[];
    };
  }>;
  adCampaignId: string;
  handleModalClose: () => void;
  setFormData: Dispatcher<CampaignFormData>;
}) => {
  const uploadFile = useFileUpload();

  const { errors, isFormInvalid } = useValidation(validationsBrandedObjects);
  const [name, setName] = useState('');
  const [adType, setAdType] = useState<AdType>(AdType.THREE_D);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState<File | null>(null);
  const [texture, setTexture] = useState<File | null>(null);
  const [preview, setPreview] = useState<File | null>(null);

  const [upsertBrandedObject] = useReneMutation(UPSERT_BRANDED_OBJECT_MUTATION, {
    onCompleted(data: { UpsertBrandedObject: BrandedObjectData }) {
      const promises: Promise<Response | void>[] = [];
      if (texture && model && preview) {
        promises.push(uploadFile(data.UpsertBrandedObject.model.uploadUrl, model));
        promises.push(uploadFile(data.UpsertBrandedObject.texture.uploadUrl, texture));
        promises.push(uploadFile(data.UpsertBrandedObject.image.uploadUrl, preview));
        Promise.all(promises).then(() => {
          refetch().then(() => {
            setFormData((prev) => ({
              ...prev,
              selectedBrandedObjectsIds: [...prev.selectedBrandedObjectsIds, data.UpsertBrandedObject.brandedObjectId],
              selectedBrandedObjects: [...prev.selectedBrandedObjects, data.UpsertBrandedObject],
            }));
            setLoading(false);
            handleModalClose();
          });
        });
      }
    },
  });

  const handleUpsertBrandedObject = () => {
    if (isFormInvalid({ name, adType, model, texture, preview })) return;
    setLoading(true);
    const variables = {
      adCampaignId,
      name,
      adType,
      texture: { extension: texture?.type.split('/')[1] },
      model: { extension: model?.type.split('/')[1] },
      image: { extension: preview?.type.split('/')[1] },
    };
    upsertBrandedObject({ variables });
  };

  return (
    <div className="branded-object-ads">
      <div className="branded-object-ads__inputs">
        <Input
          newStyle
          label="Name"
          value={name}
          placeholder="Enter Ad name"
          handleInput={(e) => setName(e.target.value)}
          errorMessage={errors?.name}
        />
        <MultiSelect
          label="Object type"
          selectedValues={adType}
          options={[{ value: AdType.THREE_D, label: '3D object' }]}
          onChange={(selected) => {
            setAdType(selected as AdType);
          }}
        />
        <InlineFileUpload
          onFileSelect={(file) => setModel(file)}
          accept=".obj"
          label="Object file (.obj)"
          errorMessage={errors?.model}
        />
        <InlineFileUpload
          onFileSelect={(file) => setTexture(file)}
          accept=".png"
          label="Texture file (.png)"
          errorMessage={errors?.texture}
        />
        <InlineFileUpload
          onFileSelect={(file) => setPreview(file)}
          accept=".jpg, .jpeg"
          label="Preview file (.jpg, .jpeg)"
          errorMessage={errors?.preview}
        />
      </div>
      <div className="create-ad-modal__footer">
        <button type="button" className="btn-neutral-ghost" onClick={handleModalClose}>
          Cancel
        </button>
        <button type="button" className="btn-primary-solid" onClick={handleUpsertBrandedObject}>
          {loading ? <Spinner size="sm" /> : 'Create Ad'}
        </button>
      </div>
    </div>
  );
};

const CreateAdModal = ({
  adCampaignId,
  refetch,
  setFormData,
  setIsModalOpen,
}: {
  adCampaignId: string;
  setFormData: Dispatcher<CampaignFormData>;
  refetch: Refetch<{
    Ads: {
      ads: AdData[];
      brandedObjects: BrandedObjectData[];
    };
  }>;
  setIsModalOpen: Dispatcher<boolean>;
}) => {
  const [adType, setAdType] = useState<'branded-object' | 'image-video'>('branded-object');

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="create-ad-modal">
      <div className="create-ad-modal__heading">
        <h1>Create Ad</h1>
        <button type="button" onClick={handleModalClose}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="create-ad-modal__ad-type">
        <RadioButtons
          newStyle
          label="Ad type"
          options={[
            { label: 'Branded Object', value: 'branded-object' },
            { label: 'Image/Video', value: 'image-video' },
          ]}
          selectedValue={adType}
          setSelectedValue={setAdType}
        />
      </div>
      <div className="create-ad-modal__content">
        {adType === 'branded-object' ? (
          <BrandedObjectAds
            refetch={refetch}
            adCampaignId={adCampaignId}
            handleModalClose={handleModalClose}
            setFormData={setFormData}
          />
        ) : (
          <ImageVideoAds
            setFormData={setFormData}
            refetch={refetch}
            adCampaignId={adCampaignId}
            handleModalClose={handleModalClose}
          />
        )}
      </div>
    </div>
  );
};

export default CreateAdModal;
