import React, { MouseEventHandler, ReactNode, useState } from 'react';
import Icon from '../Icon/Icon';
import './banner-with-dropdown.scss';

type MenuItem = {
  label: ReactNode;
  onClick: () => void;
};

type BannerWithDropdownProps = {
  title: string;
  subLabel?: string;
  id?: string;
  children: React.ReactNode;
  loading?: boolean;
  menuItems?: MenuItem[];
};

const BannerWithDropdown: React.FC<BannerWithDropdownProps> = ({
  title,
  subLabel,
  id,
  children,
  loading,
  menuItems,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const toggleMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setMenuOpen((prev) => !prev);
  };

  const copyToClipboard: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    id && navigator.clipboard.writeText(id);
  };

  return (
    <div className={`banner-with-dropdown ${loading ? ' loading' : ''}`}>
      <div className="banner-with-dropdown__header" onClick={toggleDropdown}>
        <button className={`banner-with-dropdown__toggle ${isOpen ? 'open' : ''}`}>
          <Icon name="chevron-down" />
        </button>
        <div className="banner-with-dropdown__info">
          <span className="banner-with-dropdown__title">{title}</span>
          {subLabel && <span className="banner-with-dropdown__sub-label">{subLabel}</span>}
          {id && (
            <span className="banner-with-dropdown__sub-label">
              {id}
              <button onClick={copyToClipboard} className="copy-icon">
                <Icon name="copy" />
              </button>
            </span>
          )}
        </div>
        {!!menuItems?.length && (
          <button onClick={toggleMenu} className="banner-with-dropdown__menu-button">
            <Icon name="dots" />
            {menuOpen && (
              <div className="banner-with-dropdown__menu">
                {menuItems.map((item, idx) => (
                  <div key={idx} onClick={item.onClick} className="menu-item">
                    {item.label}
                  </div>
                ))}
              </div>
            )}
          </button>
        )}
      </div>
      {isOpen && (
        <div className="banner-with-dropdown__content" id={`dropdown-content-${id}`}>
          {children}
        </div>
      )}
    </div>
  );
};

export default BannerWithDropdown;
