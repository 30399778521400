import { GET_GAME_GENRES } from '../../../../../global/gql/queries';
import { useValidation, useLazyReneQuery } from '../../../../../hooks';
import DropdownSearch from '../../../../dropdown-search/dropdown-search';
import Input from '../../../../input/input';
import Textarea from '../../../../textarea/textarea';
import FileUpload from '../../../../file-upload/file-upload';
import { GameFormData } from '../../new-game-modal';
import { validations as getValidations } from '../../validations';

import './basic-information-step.scss';

type BasicInformationStepProps = {
  updateFormData: <K extends keyof GameFormData>(step: K, data: Partial<GameFormData[K]>) => void;
  formData: GameFormData;
};

const BasicInformationStep = ({ updateFormData, formData }: BasicInformationStepProps) => {
  const { errors } = useValidation(getValidations(formData.gameDetails.operatingSystems));

  const [search, { data, loading: genresLoading }] = useLazyReneQuery<{ GameGenreSearch: string[] }>(GET_GAME_GENRES);

  const handleSearchChange = (value: string) => search({ variables: { gameGenreSearchTerm: value } });

  const genres = data?.GameGenreSearch || [];

  return (
    <div>
      <Input
        label="Title"
        name="title"
        placeholder="Enter game title"
        handleInput={(e) => updateFormData('basicInfo', { title: e.target.value })}
        value={formData.basicInfo.title}
        errorMessage={errors?.title}
        newStyle
      />
      <Textarea
        label="Description"
        name="description"
        value={formData.basicInfo.description}
        handleInput={(e) => updateFormData('basicInfo', { description: e.target.value })}
        placeholder="Enter game description"
        showCounter
        maxLength={100}
        errorMessage={errors?.description}
        newStyle
      />
      {/* TODO: multi select */}
      <DropdownSearch
        label="Genre"
        options={genres.map((genre) => ({ label: genre, value: genre })) || []}
        selectedValue={formData.basicInfo.genres[0]}
        handleSelect={(value) => updateFormData('basicInfo', { genres: [value] })}
        handleSearchChange={handleSearchChange}
        placeholder="Search for game category..."
        loading={genresLoading}
        name="category"
      />
      <div className="image-upload-grid">
        <FileUpload
          label="Icon Image"
          setFiles={(files: File[]) => updateFormData('basicInfo', { iconImage: files[0] })}
          description="96 x 96 px"
          errorMessage={errors?.iconImage}
          newStyle
        />
        <FileUpload
          label="Cover Image"
          setFiles={(files: File[]) => updateFormData('basicInfo', { coverImage: files[0] })}
          description="512 x 256 px"
          errorMessage={errors?.coverImage}
          newStyle
        />
      </div>
    </div>
  );
};

export default BasicInformationStep;
