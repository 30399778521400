import { useMemo, useState } from 'react';
import {
  CampaignData,
  CampaignsData,
  ChainsData,
  Dispatcher,
  OrganizationData,
  Refetch,
} from '../../../../../global/interfaces';
import { AdCampaignCheckpoint, CampaignGoal, Gender } from '../../../../../global/consts';
import { GET_CHAINS, GET_REGIONS_AND_COUNTRIES } from '../../../../../global/gql/queries';
import { UPSERT_AD_CAMPAIGN_MUTATION } from '../../../../../global/gql/mutations';
import { useReneMutation, useReneQuery, useValidation } from '../../../../../hooks';
import { MultiSelect } from '../../../../multi-select/multi-select';
import { CampaignFormData } from '../../create-campaign-modal';
import { validations } from './validations';
import Input from '../../../../input/input';
import Spinner from '../../../../spinner/spinner';
import Checkbox from '../../../../checkbox/checkbox';
import SmallErrorMsg from '../../../../small-error-msg/small-error-msg';

import './overview.scss';

interface OverviewProps {
  adCampaignId?: string;
  data?: CampaignFormData['overview'];
  deleteAdCampaignLoading: boolean;
  setActiveStep: (step: number) => void;
  setFormData: Dispatcher<CampaignFormData>;
  handleDeleteAdCampaign: () => void;
  refetch: Refetch<
    | {
        Organization: OrganizationData;
      }
    | {
        AdCampaigns: CampaignsData;
      }
    | undefined
  >;
}

const initialOverviewData: CampaignFormData['overview'] = {
  name: '',
  adCampaignObjectiveType: CampaignGoal.BRAND_AWARENESS,
  market: [],
  targetCountries: [],
  ageRange: { min: 18, max: 65 },
  gender: [],
  chainId: '',
};

const Overview: React.FC<OverviewProps> = ({
  adCampaignId,
  data,
  deleteAdCampaignLoading,
  setActiveStep,
  setFormData,
  handleDeleteAdCampaign,
  refetch,
}) => {
  const { errors, isFormInvalid } = useValidation(validations);
  const [overviewData, setOverviewData] = useState<CampaignFormData['overview']>(data || initialOverviewData);

  const [upsertCampaign, { loading: upsertCampaignLoading }] = useReneMutation(UPSERT_AD_CAMPAIGN_MUTATION, {
    onCompleted(data: { UpsertAdCampaign: CampaignData }) {
      setFormData((prev: CampaignFormData) => ({
        ...prev,
        adCampaignId: data.UpsertAdCampaign.adCampaignId,
        overview: {
          name: data.UpsertAdCampaign.name,
          targetCountries: data.UpsertAdCampaign.targetCountries || [],
          gender: data.UpsertAdCampaign.gender || [],
          ageRange: { min: data.UpsertAdCampaign.ageFrom, max: data.UpsertAdCampaign.ageTo },
          chainId: data.UpsertAdCampaign.chainId?.toString() || '',
          adCampaignObjectiveType: data.UpsertAdCampaign.adCampaignObjectiveType,
          market: [],
        },
      }));
      setActiveStep(2);
      refetch();
    },
  });

  const { data: chainsData, loading: chainsLoading } = useReneQuery<{
    Chains: ChainsData;
  }>(GET_CHAINS);

  const updateOverview = (data: Partial<CampaignFormData['overview']>) => {
    setOverviewData((prev) => ({ ...prev, ...data }));
  };

  const { data: regionsAndCountriesData, loading: regionsAndCountriesLoading } = useReneQuery<{
    RegionsAndCountries: {
      items: { region: { code: string; name: string }; countries: { name: string; code: string }[] }[];
    };
  }>(GET_REGIONS_AND_COUNTRIES);

  const handleGenderCheckbox = (value: Gender) => {
    if (overviewData.gender.some((key) => key === value)) {
      return updateOverview({
        gender: [...(overviewData.gender as Array<Gender>).filter((key) => key !== value)],
      });
    }
    updateOverview({ gender: [...overviewData.gender, value] });
  };

  const regions =
    regionsAndCountriesData?.RegionsAndCountries.items
      .map((item) => ({
        value: item.region.code,
        label: item.region.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const availableCountries = useMemo(() => {
    const allCountries =
      regionsAndCountriesData?.RegionsAndCountries.items.flatMap((item) =>
        item.countries.map((country) => ({
          ...country,
          regionCode: item.region.code,
        })),
      ) || [];

    const filteredCountries =
      overviewData.market && overviewData.market.length > 0
        ? allCountries.filter((country) => overviewData.market.includes(country.regionCode))
        : allCountries;

    return filteredCountries.sort((a, b) => a.name.localeCompare(b.name));
  }, [regionsAndCountriesData, overviewData.market]);

  const saveCampaign = () => {
    if (isFormInvalid(overviewData)) return;
    let variables = {
      adCampaignId: adCampaignId || undefined,
      name: overviewData.name,
      adCampaignObjectiveType: overviewData.adCampaignObjectiveType,
      targetCountryCodes: overviewData.targetCountries?.map((country) => country.countryCode),
      ageFrom: overviewData.ageRange.min,
      ageTo: overviewData.ageRange.max,
      gender: overviewData.gender,
      chainId: overviewData.chainId || undefined,
      checkpoint: AdCampaignCheckpoint.ADS_PLACEMENT,
    };

    upsertCampaign({ variables });
  };

  const chainOptions = useMemo(() => {
    const env = process.env.REACT_APP_RENE_ENVIRONMENT === 'prd' ? 'mainnet' : 'testnet';
    return (
      chainsData?.Chains.items
        .filter((chain) => chain[env])
        .map((chain) => ({
          value: chain[env]?.chainId?.toString(),
          label: chain.name,
        })) || []
    );
  }, [chainsData]);

  return (
    <div className="campaign-overview">
      <div className="campaign-overview__content">
        <h2>Define your campaign</h2>
        <h3>Define Campaign Goals and Audience to Set the Foundation for Your Ads</h3>
        <div className="campaign-overview__info">
          <h4>Campaign Info</h4>
          <Input
            newStyle
            label="Name"
            placeholder="Enter your campaign name"
            value={overviewData.name}
            handleInput={(e) =>
              updateOverview({
                name: e.target.value,
              })
            }
            errorMessage={errors?.name}
          />
          <MultiSelect
            label="Objective"
            options={[{ value: CampaignGoal.BRAND_AWARENESS, label: 'Brand Awareness' }]}
            selectedValues={overviewData.adCampaignObjectiveType}
            onChange={(selected) => updateOverview({ adCampaignObjectiveType: selected as CampaignGoal })}
            placeholder="Select objective"
            errorMessage={errors?.adCampaignObjectiveType}
          />
          <MultiSelect
            label="Campaign Reports Chain"
            options={chainOptions}
            selectedValues={overviewData.chainId}
            onChange={(selected) => updateOverview({ chainId: selected as string })}
            placeholder="Select campaign reports chain"
            loading={chainsLoading}
          />
        </div>
        <div className="campaign-overview__audience">
          <h4>Target Audience</h4>
          <div>
            <MultiSelect
              label="Market"
              options={regions}
              selectedValues={overviewData.market}
              onChange={(selected) => {
                updateOverview({
                  market: selected as string[],
                });
              }}
              placeholder="Select market"
              multiSelect={true}
              loading={regionsAndCountriesLoading}
            />
            <MultiSelect
              label="Countries"
              multiSelect={true}
              options={availableCountries}
              valueKey="code"
              labelKey="name"
              selectedValues={overviewData.targetCountries.map((country) => country.countryCode)}
              onChange={(selected) =>
                updateOverview({
                  targetCountries: (selected as string[]).map((countryCode) => ({
                    countryCode,
                    countryName: availableCountries.find((c) => c.code === countryCode)?.name || '',
                  })),
                })
              }
              placeholder="Select countries"
              loading={regionsAndCountriesLoading}
              errorMessage={errors?.targetCountries}
            />
            <div className="campaign-overview__audience_gender">
              <label>Gender</label>
              <div className="campaign-overview__audience_gender_checkboxes">
                <Checkbox
                  newStyle
                  value={overviewData.gender?.some((key) => key === Gender.MALE)}
                  setValue={() => handleGenderCheckbox(Gender.MALE)}
                >
                  Male
                </Checkbox>
                <Checkbox
                  newStyle
                  value={overviewData.gender?.some((key) => key === Gender.FEMALE)}
                  setValue={() => handleGenderCheckbox(Gender.FEMALE)}
                >
                  Female
                </Checkbox>
                <Checkbox
                  newStyle
                  value={overviewData.gender?.some((key) => key === Gender.OTHER)}
                  setValue={() => handleGenderCheckbox(Gender.OTHER)}
                >
                  Other
                </Checkbox>
              </div>
              <SmallErrorMsg message={errors?.gender} />
            </div>
            <div className="campaign-overview__audience_age">
              <Input
                label="From age"
                placeholder="Enter age"
                value={overviewData.ageRange.min}
                handleInput={(e) =>
                  updateOverview({
                    ageRange: { ...overviewData.ageRange, min: Number(e.target.value) },
                  })
                }
                newStyle
              />
              <Input
                label="To age"
                placeholder="Enter age"
                value={overviewData.ageRange.max}
                handleInput={(e) =>
                  updateOverview({
                    ageRange: { ...overviewData.ageRange, max: Number(e.target.value) },
                  })
                }
                newStyle
              />
            </div>
          </div>
        </div>
      </div>
      <div className="create-campaign-modal__main_footer">
        <div>
          <button className="btn-negative-ghost" onClick={handleDeleteAdCampaign}>
            {deleteAdCampaignLoading ? <Spinner size="sm" /> : 'Discard'}
          </button>
        </div>
        <div>
          <button className="btn-primary-solid" onClick={saveCampaign}>
            {upsertCampaignLoading ? <Spinner size="sm" /> : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Overview;
