import { useMemo } from 'react';
import { CampaignData, Event } from '../../global/interfaces';
import SingleCampaign from '../single-campaign/single-campaign';
import './campaigns-list.scss';

type CampaignsListProps = {
  campaigns: CampaignData[];
  userIsDemo: boolean;
  handleCleanCampaign?: (e: Event['Button']) => void;
  showImage?: boolean;
  showAdsDetails?: boolean;
};

const CampaignsList = ({
  campaigns,
  userIsDemo,
  handleCleanCampaign,
  showImage,
  showAdsDetails,
}: CampaignsListProps) => {
  const prepackCampaigns = useMemo(() => {
    return campaigns?.reduce<{
      future: CampaignData[];
      passed: CampaignData[];
      present: CampaignData[];
    }>(
      (prev, current) => {
        if (current.status === 'FINISHED' || current.status === 'STOPPED') {
          prev.passed.push(current);
        } else if (current.status === 'IN_PROGRESS') {
          prev.present.push(current);
        } else if (current.status === 'SCHEDULED' || current.status === 'DRAFT') {
          prev.future.push(current);
        }

        return prev;
      },
      {
        future: [],
        passed: [],
        present: [],
      },
    );
  }, [campaigns]);

  return (
    <div className="campaigns-list">
      {!!prepackCampaigns?.future?.length && (
        <>
          <h3>Future campaigns</h3>
          {prepackCampaigns?.future?.map((campaign) => (
            <SingleCampaign
              key={campaign.adCampaignId}
              campaign={campaign}
              userIsDemo={userIsDemo}
              handleCleanCampaign={handleCleanCampaign}
              showImage={showImage}
              showAdsDetails={showAdsDetails}
            />
          ))}
        </>
      )}
      {!!prepackCampaigns?.present?.length && (
        <>
          <h3>Active campaigns</h3>
          {prepackCampaigns?.present?.map((campaign) => (
            <SingleCampaign
              key={campaign.adCampaignId}
              campaign={campaign}
              userIsDemo={userIsDemo}
              handleCleanCampaign={handleCleanCampaign}
              showImage={showImage}
              showAdsDetails={showAdsDetails}
            />
          ))}
        </>
      )}
      {!!prepackCampaigns?.passed?.length && (
        <>
          <h3>Past campaigns</h3>
          {prepackCampaigns?.passed?.map((campaign) => (
            <SingleCampaign
              key={campaign.adCampaignId}
              campaign={campaign}
              userIsDemo={userIsDemo}
              handleCleanCampaign={handleCleanCampaign}
              showImage={showImage}
              showAdsDetails={showAdsDetails}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default CampaignsList;
