export const TOKEN = 'R$n$_T0K$n';
export const DEFAULT_ERROR_MESSAGE = 'Oopps something went wrong!';
export const NAME_VALIDATION_REGEX = /^[a-zA-Z\s]+$/;
export const USERNAME_VALIDATION_REGEX = /^[a-zA-Z]+$/;

export const PASSWORD_VALIDATION_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,16})/;
export const EMAIL_VALIDATION_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const IMAGE_VALIDATION_REGEX = /[/.](jpg|jpeg|png|webp)$/i;
export const CSV_VALIDATION_REGEX = /text\/csv/i;
export const RE_DIGIT = new RegExp(/^\d+$/);
export const URL_VALIDATION_REGEX = /^(https?):\/\/[^\s/$.?#].[^\s]*$/i;
export const URL_YOUTUBE_VALIDATION_REGEX = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.*/;

export const allowedImageTypes = ['image/png', 'image/jpeg', 'image/webp'];
export const allowedVideoTypes = ['video/mp4', 'video/webm'];
export const autosaveInterval = 2000;

export const orgTypeOptions = [
  {
    title: 'General',
    description: 'Allows all roles',
    key: 'GENERAL',
  },
  {
    title: 'Game Integration only',
    description: 'Allows only owner, admin and developer roles',
    key: 'GAME',
  },
  {
    title: 'Advertisement only ',
    description: 'Allows only owner, admin and advertiser roles',
    key: 'ADVERTISEMENT',
  },
  {
    title: 'Creator only',
    description: 'Allows only owner, admin and creator roles',
    key: 'CREATOR',
  },
];

export enum CampaignStatus {
  DRAFT = 'Draft',
  FINISHED = 'Finished',
  IN_PROGRESS = 'In progress',
  SCHEDULED = 'Scheduled',
  STOPPED = 'Stopped',
  UNSCHEDULED = 'Unscheduled',
}

export enum UserRole {
  GAMER = 'GAMER',
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  CREATOR = 'CREATOR',
  DEVELOPER = 'DEVELOPER',
  ADVERTISER = 'ADVERTISER',
}

export enum GameStatus {
  CONNECT_CONFIRMED = 'CONNECT_CONFIRMED',
  CONNECT_UNCONFIRMED = 'CONNECT_UNCONFIRMED',
  DISCONNECTED = 'DISCONNECTED',
}

export enum TokenType {
  GAMER = 'gamer-view',
  ORG = 'org-view',
}

export enum NotificationType {
  INFO = 'INFO',
  ERROR = 'ERROR',
}

export enum TransactionType {
  MINT = 'MINT',
  TRANSFER = 'TRANSFER',
}

export enum TransactionStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

export enum AttributeTypes {
  string = 'Text',
  number = 'Number',
  boost_percentage = 'Boost Percentage',
  date = 'Date',
}

export enum AssetType {
  BRANDED = 'branded',
  OWNABLE = 'ownable',
}

export enum AssetTypeId {
  ownableAssetId = 'ownableAssetId',
  brandedAssetId = 'brandedAssetId',
}

export enum EventTypes {
  SIGN_UP_STARTED = 'SIGN_UP_STARTED',
  GAME_CREATE_STARTED = 'GAME_CREATE_STARTED',
  OWNABLE_ASSET_STARTED = 'OWNABLE_ASSET_STARTED',
  OWNABLE_ASSET_GAME_ENGINE_FILES_UPLOAD_COMPLETED = 'OWNABLE_ASSET_GAME_ENGINE_FILES_UPLOAD_COMPLETED',
  OWNABLE_ASSET_GAME_ENGINE_FILES_UPLOAD_STARTED = 'OWNABLE_ASSET_GAME_ENGINE_FILES_UPLOAD_STARTED',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum AdType {
  VIDEO = 'VIDEO',
  BANNER = 'BANNER',
  THREE_D = 'THREE_D',
  TWO_D = 'TWO_D',
}

export enum AdTypeLabel {
  VIDEO = 'Video',
  BANNER = 'Image',
  THREE_D = '3D branded object',
  TWO_D = '2D branded object',
}

export enum CampaignAssetType {
  BANNER = 'BANNER',
  BRANDED_ASSET = 'BRANDED_ASSET',
}

export enum CampaignGoal {
  BRAND_AWARENESS = 'BRAND_AWARENESS',
  PRODUCT_PLACEMENT = 'PRODUCT_PLACEMENT',
}

export enum CampaignGoalLabel {
  BRAND_AWARENESS = 'Brand Awareness',
  PRODUCT_PLACEMENT = 'Product Placement',
}

export enum AdEngagementInterval {
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  LAST_YEAR = 'LAST_YEAR',
  CUSTOM = 'CUSTOM',
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
}

export const adSurfaceTypes: { [key: string]: string } = {
  BANNER: 'Banner',
  VIDEO: 'Video',
};

export enum AdSurfaceInteractivity {
  INTERACTIVE = 'INTERACTIVE',
  STATIC = 'STATIC',
}

export enum AdResolutionIab {
  v120x20 = 'v120x20',
  v120x60 = 'v120x60',
  v160x600 = 'v160x600',
  v168x28 = 'v168x28',
  v216x36 = 'v216x36',
  v300x50 = 'v300x50',
  v300x250 = 'v300x250',
  v300x1050 = 'v300x1050',
  v320x50 = 'v320x50',
  v640x1136 = 'v640x1136',
  v728x90 = 'v728x90',
  v750x1134 = 'v750x1134',
  v970x90 = 'v970x90',
  v970x250 = 'v970x250',
  v1080x1920 = 'v1080x1920',
  v1920x1080 = 'v1920x1080',
}

export enum AdCampaignCheckpoint {
  DEFINE_CAMPAIGN = 'DEFINE_CAMPAIGN',
  SELECT_AD = 'SELECT_AD',
  ADS_PLACEMENT = 'ADS_PLACEMENT',
  BUDGETING = 'BUDGETING',
  FINAL_OVERVIEW = 'FINAL_OVERVIEW',
  DONE = 'DONE',
}
