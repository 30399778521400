import { combineValidations, commonValidations } from '../../../../global/validations';

export const validations = (operatingSystems: string[]) => ({
  title: commonValidations.requiredTrimmed(),
  description: commonValidations.requiredTrimmed(),
  iconImage: commonValidations.fileImage(),
  coverImage: commonValidations.fileImage(),
  ...(operatingSystems.includes('IOS') && {
    iosUrl: combineValidations([commonValidations.required(), commonValidations.url()]),
    iosBundleId: commonValidations.required(),
  }),
  ...(operatingSystems.includes('ANDROID') && {
    androidUrl: combineValidations([commonValidations.required(), commonValidations.url()]),
    androidPackageName: commonValidations.required(),
  }),
  ...(operatingSystems.includes('WEB') && {
    webUrl: combineValidations([commonValidations.required(), commonValidations.url()]),
  }),
});
