import { useState } from 'react';
import { useReneMutation } from '../../../../hooks';
import { DELETE_AD_SURFACE_MUTATION } from '../../../../global/gql/mutations';
import { useUpdatePopUpContext } from '../../../../context/update-message-context';
import { AdSurfaceData } from '../../../../global/interfaces';
import AdSurfaceFields from './ad-surface-fields';
import Icon from '../../../../components/Icon/Icon';
import Modal from '../../../../components/modal/modal';
import DeleteModal from '../../../../components/modal/delete-modal/delete-modal';

import './ad-surface.scss';

const AdSurface = ({
  surface,
  errors,
  allowCreateSurface,
  updateSurface,
}: {
  surface: AdSurfaceData;
  errors?: { [key: string]: string };
  allowCreateSurface: boolean;
  updateSurface: (updatedSurface: any | { adSurfaceId: string }, action?: string) => void;
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { showUpdatePopUpMessage } = useUpdatePopUpContext();

  const [deleteAdSurface, { loading }] = useReneMutation(DELETE_AD_SURFACE_MUTATION);

  const handleDeleteSurface = (adSurfaceId: string) => {
    const variables = { adSurfaceId };
    deleteAdSurface({ variables }).then(({ data }: { data: { DeleteAdSurface: boolean } }) => {
      if (data.DeleteAdSurface) {
        updateSurface({ adSurfaceId }, 'delete');
        showUpdatePopUpMessage('Changes saved successfully');

        setIsDeleteModalOpen(false);
      }
    });
  };

  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text);
  };

  return (
    <div key={surface?.adSurfaceId} className="ad-surface-item">
      <div className="ad-surface-item__title">
        <div>
          <span className="label">{surface?.adType === 'BANNER' ? 'Banner' : 'Video'} Surface ID: </span>
          <span className="value">{surface?.adSurfaceId}</span>
          <button className="secondary-btn" type="button" onClick={() => handleCopy(surface?.adSurfaceId)}>
            <Icon name="copy" />
          </button>
        </div>
        {allowCreateSurface && (
          <button
            id={surface?.adSurfaceId}
            className="secondary-btn"
            type="button"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            <Icon name="trash" />
          </button>
        )}
        <Modal isOpen={isDeleteModalOpen}>
          <DeleteModal
            text="this surface"
            setIsModalOpen={setIsDeleteModalOpen}
            deleteHandler={() => handleDeleteSurface(surface.adSurfaceId)}
            isLoading={loading}
          />
        </Modal>
      </div>
      <div className="ad-surface-item__body">
        <AdSurfaceFields allowCreateSurface={allowCreateSurface} surface={surface} updateSurface={updateSurface} />
      </div>
    </div>
  );
};

export default AdSurface;
