import React from 'react';
import BannerWithDropdown from '../../../../banner-with-dropdown/banner-with-dropdown';
import { GameFormData } from '../../new-game-modal';

import './review-and-create-step.scss';

type ReviewAndCreateStepProps = {
  formData: GameFormData;
};

const ReviewAndCreateStep = ({ formData }: ReviewAndCreateStepProps) => (
  <div className="review-and-create-step">
    <div className="review-and-create-step__title">
      <h2>Review and create game</h2>
      <p>Review your game details and create when ready</p>
    </div>

    <div className="review-and-create-step__review">
      <BannerWithDropdown title="Basic Information">
        <p>
          <strong>Title:</strong> {formData.basicInfo.title}
        </p>
        <p>
          <strong>Description:</strong> {formData.basicInfo.description}
        </p>
        <p>
          <strong>Genres:</strong> {formData.basicInfo.genres.join(', ')}
        </p>
        {formData.basicInfo.iconImage && (
          <p>
            <strong>Icon Image:</strong> <img src={URL.createObjectURL(formData.basicInfo.iconImage)} alt="icon" />
          </p>
        )}
        {formData.basicInfo.coverImage && (
          <p>
            <strong>Cover Image:</strong> <img src={URL.createObjectURL(formData.basicInfo.coverImage)} alt="cover" />
          </p>
        )}
      </BannerWithDropdown>

      <BannerWithDropdown title="Game Details">
        <p>
          <strong>Operating Systems:</strong> {formData.gameDetails.operatingSystems.join(', ')}
        </p>
        {formData.gameDetails.iosData?.url && (
          <>
            <p>
              <strong>iOS URL:</strong> {formData.gameDetails.iosData.url}
            </p>
            <p>
              <strong>iOS Bundle ID:</strong> {formData.gameDetails.iosData.bundleId}
            </p>
          </>
        )}
        {formData.gameDetails.androidData?.url && (
          <>
            <p>
              <strong>Android URL:</strong> {formData.gameDetails.androidData.url}
            </p>
            <p>
              <strong>Android Package Name:</strong> {formData.gameDetails.androidData.packageName}
            </p>
          </>
        )}
        {formData.gameDetails.webData?.url && (
          <p>
            <strong>Web URL:</strong> {formData.gameDetails.webData.url}
          </p>
        )}
        <p>
          <strong>IAB Categories:</strong> {formData.gameDetails.iabCategories?.map((c) => c.name)?.join(', ')}
        </p>
        <p>
          <strong>Monthly Active Users (MAU):</strong> {formData.gameDetails.monthlyActiveUsers}
        </p>
      </BannerWithDropdown>
    </div>
  </div>
);

export default ReviewAndCreateStep;
