import { useState, useRef, useEffect } from 'react';
import Icon from '../Icon/Icon';
import SmallErrorMsg from '../small-error-msg/small-error-msg';
import Spinner from '../spinner/spinner';
import './searchable-multi-select.scss';

interface SearchableMultiSelectProps<T> {
  options: T[] | string[];
  selectedValues?: string[] | string;
  onChange: (selected: string[] | string) => void;
  valueKey?: keyof T;
  labelKey?: keyof T;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  multiSelect?: boolean;
  label?: string;
  errorMessage?: string;
  apiSearch?: boolean;
  onSearch?: (searchTerm: string) => void;
  loading?: boolean;
}

export const SearchableMultiSelect = <T extends Record<string, any>>({
  options,
  selectedValues = [],
  onChange,
  valueKey = 'value',
  labelKey = 'label',
  placeholder = 'Select options',
  className = '',
  disabled = false,
  multiSelect = false,
  label,
  errorMessage,
  apiSearch = false,
  onSearch,
  loading = false,
}: SearchableMultiSelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setSearchTerm('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (apiSearch && onSearch) {
      onSearch(searchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleOptionClick = (value: string) => {
    let updatedSelection: string[] | string;

    if (multiSelect) {
      const currentSelection = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
      updatedSelection = currentSelection.includes(value)
        ? currentSelection.filter((item) => item !== value)
        : [...currentSelection, value];
    } else {
      updatedSelection = Array.isArray(selectedValues) && selectedValues.includes(value) ? '' : value;
      setIsOpen(false);
      setSearchTerm('');
    }

    onChange(updatedSelection);
  };

  const handleRemoveValue = (valueToRemove: string, e: React.MouseEvent) => {
    e.stopPropagation();
    let updatedSelection: string[] | string;

    if (multiSelect) {
      updatedSelection = Array.isArray(selectedValues) ? selectedValues.filter((value) => value !== valueToRemove) : [];
    } else {
      updatedSelection = '';
    }

    onChange(updatedSelection);
  };

  const filteredOptions = apiSearch
    ? (options as (T | string)[])
    : (options as (T | string)[]).filter((option: T | string) => {
        const label = typeof option === 'string' ? option : String(option[labelKey!]);
        return label.toLowerCase().includes(searchTerm.toLowerCase());
      });

  const getOptionLabel = (value: string) => {
    if (typeof options[0] === 'string') {
      return value;
    }
    const option = (options as T[]).find((opt) => String(opt[valueKey!]) === value);
    // If option is not found and we're using apiSearch, just display the value
    if (option) {
      return String(option[labelKey!]);
    }
    return apiSearch ? value : '';
  };

  return (
    <div className={`searchable-multi-select ${className} ${disabled ? 'disabled' : ''}`} ref={containerRef}>
      {label && <label className="searchable-multi-select__label">{label}</label>}
      <div
        className={`searchable-multi-select__trigger ${isOpen ? 'open' : ''}`}
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        {!isOpen && (
          <div className="searchable-multi-select__selected">
            {selectedValues && (Array.isArray(selectedValues) ? selectedValues.length > 0 : selectedValues) ? (
              <div className="searchable-multi-select__tags">
                {(Array.isArray(selectedValues) ? selectedValues : [selectedValues]).filter(Boolean).map((value) => {
                  const label = getOptionLabel(value);

                  return (
                    <span key={value} className="searchable-multi-select__tag">
                      {label}
                      <button className="searchable-multi-select__remove" onClick={(e) => handleRemoveValue(value, e)}>
                        <Icon name="close" />
                      </button>
                    </span>
                  );
                })}
              </div>
            ) : (
              <span>{placeholder}</span>
            )}
          </div>
        )}
        {isOpen && (
          <input
            ref={inputRef}
            type="text"
            className="searchable-multi-select__search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            placeholder="Type to search..."
          />
        )}
        <span className="searchable-multi-select__arrow" />
      </div>

      {isOpen && !disabled && (
        <div className="searchable-multi-select__options">
          {loading ? (
            <div className="searchable-multi-select__loading">
              <Spinner size="sm" />
            </div>
          ) : filteredOptions.length === 0 ? (
            <div className="searchable-multi-select__no-results">No results found</div>
          ) : (
            filteredOptions.map((option: T | string) => {
              const value = typeof option === 'string' ? option : String(option[valueKey!]);
              const label = typeof option === 'string' ? option : String(option[labelKey!]);

              return (
                <button
                  key={value}
                  className={`searchable-multi-select__option ${
                    (Array.isArray(selectedValues) ? selectedValues.includes(value) : selectedValues === value)
                      ? 'selected'
                      : ''
                  }`}
                  onClick={() => handleOptionClick(value)}
                >
                  {multiSelect && (
                    <span className="searchable-multi-select__checkbox">
                      {Array.isArray(selectedValues) && selectedValues.includes(value) && <Icon name="checkmark" />}
                    </span>
                  )}
                  {label}
                </button>
              );
            })
          )}
        </div>
      )}
      <SmallErrorMsg message={errorMessage} />
    </div>
  );
};
