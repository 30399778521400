import { USER_FIELDS, GAME_FIELDS, ASSET_FIELDS, TRANSACTION_FIELDS } from './../fragments/index';
import { gql } from '@apollo/client';

export const CHECK_USERNAME_QUERY = gql`
  query CheckUsername($username: String!) {
    CheckUsername(input: { username: $username })
  }
`;

export const GET_USER_ROLE_QUERY = gql`
  query UserRole {
    User {
      role
      email
      isFirstTimeUser
      userId
      verified {
        isEmail
      }
    }
  }
`;

export const GET_USER_QUERY = gql`
  ${USER_FIELDS}
  query User {
    User {
      ...UserFields
      isDemo
      earnings
      userActivity {
        activity {
          image
          name
          path
          timeAgo
          type
        }
        fullName
        userId
      }
    }
  }
`;

export const GET_USER_REFERRAL_DATA = gql`
  query User {
    User {
      userId
      referralCode
      referrals {
        items {
          userId
          createdAt
          data {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_USER_POINTS = gql`
  query UserPoints($interval: Interval) {
    User {
      userId
      points(input: { interval: $interval }) {
        amount
      }
    }
  }
`;

export const GET_USER_WITH_ASSETS_QUERY = gql`
  ${USER_FIELDS}
  ${ASSET_FIELDS}
  query User($userId: String, $gameId: String) {
    User(input: { userId: $userId }) {
      assets(input: { gameId: $gameId }) {
        items {
          ...AssetFields
        }
      }
      ...UserFields
    }
  }
`;

export const GET_USER_TRANSACTIONS_QUERY = gql`
  ${TRANSACTION_FIELDS}
  query User {
    User {
      userId
      walletAddress
      transactions {
        items {
          ...TransactionFields
        }
      }
    }
  }
`;

export const GET_USER_EARNINGS = gql`
  query User {
    User {
      userId
      earnings
    }
  }
`;

export const GET_USER_BALANCE_AND_TRANSACTIONS_QUERY = gql`
  ${TRANSACTION_FIELDS}
  query User {
    User {
      userId
      isDemo
      transactions {
        items {
          ...TransactionFields
        }
      }
      balance {
        balance
        balanceId
        transactions {
          items {
            date
            amount
            status
            transactionId
            type
          }
        }
      }
    }
  }
`;

export const GET_USER_NFTS = gql`
  query UserNfts {
    User {
      userId
      nfts {
        nftId
        rarity
        multiplier
        isStaked
      }
      stakedNft {
        nftId
        rarity
        multiplier
        isStaked
      }
    }
  }
`;

export const GET_ORG_GAMES_QUERY = gql`
  query AllGames {
    Games {
      items {
        name
        gameId
        description
        image {
          url
        }
        stats {
          mintedAssets
          walletAssets
          players
        }
        adCampaignStats {
          impressions
        }
        iosData {
          bundleId
          url
        }
        androidData {
          packageName
          url
        }
        webData {
          url
        }
      }
    }
  }
`;

export const GET_GAMES_QUERY = gql`
  ${GAME_FIELDS}
  ${ASSET_FIELDS}
  query Games($gameId: String, $status: GameConnectStatus, $authorized: Boolean, $walletOnly: Boolean) {
    Games(input: { gameId: $gameId, status: $status, authorized: $authorized }) {
      items {
        assets(input: { walletOnly: $walletOnly }) {
          items {
            ...AssetFields
          }
        }
        ...GameFields
      }
    }
  }
`;

export const GET_GAMES_TRANSACTIONS_QUERY = gql`
  ${TRANSACTION_FIELDS}
  query GamesTransactions($gameId: String, $status: GameConnectStatus, $authorized: Boolean, $walletOnly: Boolean) {
    Games(input: { gameId: $gameId, status: $status, authorized: $authorized }) {
      items {
        assets(input: { walletOnly: $walletOnly }) {
          items {
            metadata {
              name
              image
            }
          }
        }
        transactions(input: { walletOnly: $walletOnly }) {
          items {
            ...TransactionFields
          }
        }
        name
        gameId
        walletAddress
        data {
          description
        }
        image {
          url
        }
        stats {
          walletAssets
        }
      }
    }
  }
`;

export const GET_GAME_TO_AUTH_QUERY = gql`
  query Games($gameId: String) {
    Games(input: { gameId: $gameId }) {
      items {
        name
        image {
          url
        }
      }
    }
  }
`;

export const GET_GAMES_GAMER_QUERY = gql`
  query GamesGamerView($gameId: String, $status: GameConnectStatus, $authorized: Boolean) {
    Games(input: { gameId: $gameId, status: $status, authorized: $authorized }) {
      items {
        name
        gameId
        category
        data {
          description
        }
        image {
          url
        }
        gameConnect {
          status
        }
        iosData {
          bundleId
          url
        }
        androidData {
          packageName
          url
        }
        webData {
          url
        }
      }
    }
  }
`;

export const GET_GAMER_PROMOTIONS = gql`
  query Promotions($limit: String, $promotionId: String) {
    Promotions(input: { limit: $limit, promotionId: $promotionId }) {
      items {
        buttonText
        buttonUrl
        isExternalUrl
        message
        promotionId
        promotionImage {
          url
        }
        title
      }
    }
  }
`;

export const GET_ORG_QUERY = gql`
  query Organization {
    Organization {
      orgId
      isActive
      name
      type
      mintLimit
      mintCount
      updatedAt
      data {
        description
        url
        discord
        twitter
      }
      image {
        url
        fileId
      }
      stats {
        mintedAssets
        walletAssets
        games
        players
        value
        collections
        adCampaigns
      }
      balance {
        balance
        balanceId
      }
      userActivity {
        activity {
          image
          name
          path
          timeAgo
          type
        }
        fullName
        userId
      }
    }
  }
`;

export const GET_ORG_BRANDS = gql`
  query OrganizationBrands {
    Organization {
      orgId
      brands {
        items {
          brandId
          description
          image {
            url
            name
            fileId
          }
          name
          updatedAt
          website
        }
      }
    }
  }
`;

export const GET_ORG_MEMBERS = gql`
  query OrganizationMembers {
    Organization {
      orgId
      members {
        items {
          userId
          role
          data {
            firstName
            lastName
          }
          image {
            url
            fileId
            uploadUrl
          }
        }
      }
    }
  }
`;

export const GET_ORG_WITH_TRANSACTIONS_QUERY = gql`
  ${TRANSACTION_FIELDS}
  query OrgWithTransactions {
    Organization {
      orgId
      balance {
        balance
        balanceId
        transactions(input: { type: CREDIT }) {
          items {
            date
            amount
            status
            transactionId
            type
          }
        }
      }
      transactions {
        items {
          ...TransactionFields
        }
      }
    }
  }
`;

export const GAME_SEARCH_QUERY = gql`
  ${GAME_FIELDS}
  query GameSearch($gameSearchTerm: String!) {
    GameSearch(input: { gameSearchTerm: $gameSearchTerm }) {
      items {
        ...GameFields
      }
    }
  }
`;

export const GAMER_GAME_SEARCH_QUERY = gql`
  query GamerGameSearch($gameSearchTerm: String!) {
    GameSearch(input: { gameSearchTerm: $gameSearchTerm }) {
      items {
        assets {
          items {
            assetId
            metadata {
              name
              image
            }
          }
        }
        name
        gameId
        data {
          description
        }
        image {
          url
        }
      }
    }
  }
`;

export const CAMPAIGNS_SEARCH_QUERY = gql`
  query AdCampaignSearch($adCampaignSearchTerm: String!) {
    AdCampaignSearch(input: { adCampaignSearchTerm: $adCampaignSearchTerm }) {
      items {
        adCampaignId
        updatedAt
        campaignImage {
          url
          extension
        }
        startDate
        endDate
        description
        name
        stats {
          impressions
          interactions
        }
      }
    }
  }
`;

export const BRANDS_SEARCH_QUERY = gql`
  query BrandSearch($brandSearchTerm: String!) {
    BrandSearch(input: { brandSearchTerm: $brandSearchTerm }) {
      items {
        brandId
        description
        image {
          url
        }
        name
        website
      }
    }
  }
`;

export const GAME_AUTHORIZATION_QUERY = gql`
  query GameConnectAuth {
    GameConnectAuth {
      authId
      gameId
      userId
      authorized
      name
    }
  }
`;

export const USER_SEARCH_QUERY = gql`
  ${USER_FIELDS}
  query UserSearch(
    $userSearchTerm: String!
    $gameId: String
    $excludeCurrent: Boolean
    $orgFilter: UserSearchOrgFilter
  ) {
    UserSearch(
      input: {
        userSearchTerm: $userSearchTerm
        gameId: $gameId
        excludeCurrent: $excludeCurrent
        orgFilter: $orgFilter
      }
    ) {
      items {
        ...UserFields
      }
    }
  }
`;

export const GET_CHAINS = gql`
  query Chains($chainSymbol: String, $isTestNet: Boolean) {
    Chains(input: { chainSymbol: $chainSymbol, isTestNet: $isTestNet }) {
      items {
        chainSymbol
        name
        mainnet {
          chainId
          name
        }
        testnet {
          chainId
          name
        }
      }
    }
  }
`;

export const GET_AD_SURFACES_QUERY = gql`
  query AdSurfaces($ownableAssetId: String, $brandedAssetId: String, $gameId: String) {
    AdSurfaces(input: { gameId: $gameId, ownableAssetId: $ownableAssetId, brandedAssetId: $brandedAssetId }) {
      items {
        adSurfaceId
        adType
        interactivity
        resolutionIab
        contentTags {
          name
          taxonomyContentId
        }
        placeholder {
          url
        }
        floorPrice
        maxWidth
        maxHeight
        updatedAt
        adCampaignStats {
          impressions
        }
      }
    }
  }
`;

export const GET_ADS = gql`
  query Ads {
    Ads {
      ads {
        adId
        name
        banner {
          url
        }
      }
      brandedObjects {
        brandedObjectId
        name
        image {
          url
        }
      }
    }
  }
`;

export const AD_ENGAGEMENT_QUERY = gql`
  query AdEngagement(
    $adCampaignId: String!
    $engagementType: AdEngagementType
    $day: String
    $week: String
    $month: String
    $year: String
    $timeType: TimeType!
    $amount: Int!
  ) {
    AdEngagement(
      input: {
        adCampaignId: $adCampaignId
        engagementType: $engagementType
        day: $day
        week: $week
        month: $month
        year: $year
        timeType: $timeType
        amount: $amount
      }
    ) {
      impression
      interaction
      intervalDate
    }
  }
`;

export const GET_AD_CAMPAIGNS_QUERY = gql`
  query AdCampaigns {
    AdCampaigns {
      items {
        adCampaignId
        updatedAt
        startDate
        endDate
        name
        isDemo
        status
        adCount
        usedImpressions
        usedCost
      }
    }
  }
`;

export const GET_AD_CAMPAIGN_QUERY = gql`
  query AdCampaign($adCampaignId: String) {
    AdCampaigns(input: { adCampaignId: $adCampaignId }) {
      items {
        adCampaignId
        updatedAt
        ageFrom
        ageTo
        status
        startDate
        endDate
        chainId
        name
        usedImpressions
        usedCost
        isDemo
        adCampaignType
        gender
        contentTags {
          name
          taxonomyContentId
        }
        adCampaignGenres {
          name
        }
        targetCountries {
          countryName
          countryCode
        }
        budget {
          countryName
          countryCode
          pricingType
          cpm
          totalImpressions
        }
        ads {
          items {
            interactionHtml
            adId
            adType
            updatedAt
            isActive
            isValid
            banner {
              url
            }
            video {
              url
            }
            contentTags {
              name
              taxonomyContentId
            }
            externalBannerUrl
            name
            stats {
              impressions
              interactions
            }
          }
        }
        brandedObjects {
          items {
            brandedObjectId
            brandedObjectType
            name
            image {
              url
            }
            contentTags {
              name
              taxonomyContentId
            }
          }
        }
      }
    }
  }
`;

export const GET_PUBLIC_AD_CAMPAIGN_QUERY = gql`
  query PublicAdCampaigns($adCampaignId: String, $onlyCurrentUser: Boolean) {
    PublicAdCampaigns(input: { adCampaignId: $adCampaignId }) {
      items {
        adCampaignId
        adCampaignType
        campaignImage {
          url
        }
        actions {
          actionId
          amount
          fulfilled
          description
          type
          url
        }
        description
        endDate
        leaderboard(input: { onlyCurrentUser: $onlyCurrentUser }) {
          items {
            amount
            rank
            user {
              data {
                firstName
                lastName
              }
              image {
                url
              }
            }
          }
        }
        name
        startDate
      }
      limit
      nextToken
    }
  }
`;

export const GET_GAME_GENRES = gql`
  query GameGenreSearch($gameGenreSearchTerm: String!) {
    GameGenreSearch(input: { gameGenreSearchTerm: $gameGenreSearchTerm })
  }
`;

export const CONTENT_SEARCH = gql`
  query ContentSearch($contentSearchTerm: String!) {
    ContentSearch(input: { contentSearchTerm: $contentSearchTerm }) {
      items {
        extension
        name
        taxonomyContentId
        tier1
        tier2
        tier3
        tier4
      }
    }
  }
`;

export const GET_USER_LOGIN_STEP_EVALUATION_QUERY = gql`
  query UserLoginStepEvaluation($email: String, $username: String) {
    UserLoginStepEvaluation(input: { email: $email, username: $username }) {
      needsPassword
    }
  }
`;

export const GET_STRIPE_CONFIG = gql`
  query StripeConfig {
    StripeConfig {
      publishableKey
    }
  }
`;

export const GET_AD_CAMPAIGN_CPM = gql`
  query AdCampaignCPM {
    AdCampaignCPM {
      cpm
    }
  }
`;

export const GET_FLOOR_PRICES = gql`
  query GetFloorPrice {
    GetFloorPrice {
      adType
      price
    }
  }
`;

export const GET_AD_OFFERS = gql`
  query UserAdOffers($input: UserAdOfferInput) {
    UserAdOffers(input: $input) {
      unviewedCount
      limit
      items {
        adOffer {
          adOfferId
          adOfferImage {
            name
            url
          }
          brand {
            image {
              url
            }
            name
          }
          buttonText
          buttonUrl
          createdAt
          message
          title
        }
        isViewed
        userAdOfferId
        viewedAt
      }
    }
  }
`;

export const GET_REGIONS_AND_COUNTRIES = gql`
  query RegionsAndCountries {
    RegionsAndCountries {
      items {
        countries {
          code
          name
        }
        region {
          code
          name
        }
      }
    }
  }
`;

export const GET_REACH_ESTIMATE = gql`
  query ReachEstimate($input: ReachEstimateInput!) {
    ReachEstimate(input: $input) {
      campaignReachEstimate
      entries {
        campaignReachEstimate
        countryCode
        gameCount
        globalMonthlyActiveUsers
        impressionsEstimate
        regionMonthlyActiveUsers
        regionVersusGlobalPercentage
      }
      gameCount
      globalMonthlyActiveUsers
      impressionsEstimate
      regionMonthlyActiveUsers
      regionVersusGlobalPercentage
    }
  }
`;

export const PRICING_QUERY = gql`
  query Pricing($input: PricingInput!) {
    Pricing(input: $input) {
      items {
        adType
        countryCode
        countryName
        cpm
        region
      }
    }
  }
`;

export const GET_BRANDED_OBJECT_PLACEMENTS_QUERY = gql`
  query BrandedObjectPlacements($input: BrandedObjectPlacementInput) {
    BrandedObjectPlacements(input: $input) {
      items {
        brandedObjectPlacementId
        categories
        gameId
        height
        length
        orgId
        width
        type
        scale
        updatedAt
        createdAt
      }
    }
  }
`;
