import { useState } from 'react';
import { DatePicker } from '../../../../components/calendar/calendar';
import { MultiSelect } from '../../../../components/multi-select/multi-select';
import Table from '../../../../components/table/table';
import { GET_REACH_ESTIMATE, GET_REGIONS_AND_COUNTRIES } from '../../../../global/gql/queries';
import { ReachEstimateData, RegionsAndCountriesData } from '../../../../global/interfaces';
import { useReneQuery } from '../../../../hooks';
import './reach-drawer-content.scss';

const ReachDrawerContent = () => {
  const { data: regionsAndCountries } = useReneQuery<{ RegionsAndCountries: RegionsAndCountriesData }>(
    GET_REGIONS_AND_COUNTRIES,
  );

  const countryCodeToName: { [code: string]: string } =
    regionsAndCountries?.RegionsAndCountries?.items
      ?.flatMap((region) => region.countries)
      ?.reduce((acc, country) => {
        acc[country.code] = country.name;
        return acc;
      }, {} as { [code: string]: string }) || {};

  const regionOptions = regionsAndCountries?.RegionsAndCountries?.items?.map((i) => i.region) || [];

  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const countriesInSelectedRegions =
    regionsAndCountries?.RegionsAndCountries?.items
      ?.filter((i) => selectedRegions.includes(i.region.code))
      .flatMap((i) => i.countries) || [];

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const { data: reachEstimate } = useReneQuery<{ ReachEstimate: ReachEstimateData }>(GET_REACH_ESTIMATE, {
    variables: {
      input: {
        countryCodes: countriesInSelectedRegions.map((c) => c.code),
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    },
  });

  const columns = [
    { header: 'Country', accessor: 'countryName' },
    { header: 'Games', accessor: 'gameCount' },
    { header: 'MAU', accessor: 'regionMonthlyActiveUsers' },
    { header: 'vs Global %', accessor: 'regionVersusGlobalPercentage' },
    { header: 'Campaign Reach', accessor: 'campaignReachEstimate' },
  ];

  const data = reachEstimate?.ReachEstimate.entries.length
    ? reachEstimate.ReachEstimate.entries.map((entry) => ({
        countryName: countryCodeToName[entry.countryCode] || entry.countryCode,
        gameCount: entry.gameCount,
        regionMonthlyActiveUsers: entry.regionMonthlyActiveUsers,
        regionVersusGlobalPercentage: entry.regionVersusGlobalPercentage,
        campaignReachEstimate: entry.campaignReachEstimate,
      }))
    : [];

  const statistics: [string, string | number][] = [
    ['Unique Games', reachEstimate?.ReachEstimate.gameCount ?? 0],
    ['Global MAU', reachEstimate?.ReachEstimate.globalMonthlyActiveUsers ?? 0],
    ['Region MAU', reachEstimate?.ReachEstimate.regionMonthlyActiveUsers ?? 0],
    ['Region vs Global', `${reachEstimate?.ReachEstimate.regionVersusGlobalPercentage ?? 0}%*`],
    ['Campaign Reach', reachEstimate?.ReachEstimate.campaignReachEstimate ?? 0],
  ];

  return (
    <div className="reach-drawer-content">
      <span>
        Estimate your campaign reach by selecting target regions, viewing active player data, and calculating potential
        audience impact across multiple markets.
      </span>
      <MultiSelect
        label="Select region"
        placeholder="Select markets and countries..."
        selectedValues={selectedRegions}
        onChange={(sel) => setSelectedRegions(Array.isArray(sel) ? sel : [sel])}
        options={regionOptions.map((r) => ({ label: r.name, value: r.code })) || []}
        multiSelect={true}
        className="reach-drawer-content__input"
      />
      <div className="reach-drawer-content__dates">
        <DatePicker label="Start Date" selectedDay={startDate} setSelectedDay={setStartDate} />
        <DatePicker label="End Date" selectedDay={endDate} setSelectedDay={setEndDate} />
      </div>
      <span className="reach-drawer-content__disclaimer">* Indicates estimated value</span>
      <Table columns={columns} data={data} statistics={statistics} />
    </div>
  );
};

export default ReachDrawerContent;
