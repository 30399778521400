import './table.scss';

type Column = {
  header: string;
  accessor: string;
};

type TableProps = {
  columns: Column[];
  data: { [key: string]: any }[];
  statistics: [string, string | number][];
};

const Table = ({ columns, data, statistics }: TableProps) => {
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col.accessor}>{col.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col) => (
                  <td key={col.accessor}>{row[col.accessor] || '--'}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="statistics">
        {statistics.map(([statName, statValue], index) => (
          <div key={index} className="statistic">
            <div className="stat-value">{statValue}</div>
            <div className="stat-name">{statName}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Table;
