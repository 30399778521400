import {
  EMAIL_VALIDATION_REGEX,
  NAME_VALIDATION_REGEX,
  PASSWORD_VALIDATION_REGEX,
  URL_VALIDATION_REGEX,
  USERNAME_VALIDATION_REGEX,
  allowedImageTypes,
} from '../consts';

type ValidationFunction = (form: { [k: string]: any }, key: string, field?: any) => string | boolean | undefined;

export const commonValidations = {
  required: (message?: string) => (form: { [k: string]: any }, key: string, field: string) => {
    return !form[key] && (message || 'This field is required');
  },
  requiredTrimmed: (message?: string) => (form: { [k: string]: any }, key: string) => {
    let field = form[key]?.trim();
    return !field && (message || 'This field is required');
  },
  email:
    (message?: string) =>
    (form: { [k: string]: any }, key: string = 'email') => {
      if (!form?.[key]) return 'This field is required';
      if (!form[key].match(EMAIL_VALIDATION_REGEX)) return message || 'Please input a valid type of email';
    },
  password: (message?: string) => (form: { [k: string]: any }, key: string) => {
    if (!form?.[key]) return 'This field is required';
    if (form[key].length > 16) return 'Password is allowed to have maximum 16 characters';
    if (!form[key].match(PASSWORD_VALIDATION_REGEX))
      return (
        message ||
        'Password must have more than 8 characters including 4 types of characters: an upper-case letter, a lower-case letter, a number, a special character'
      );
  },
  url: (message?: string) => (form: { [k: string]: any }, key: string) => {
    if (form[key] && !form[key].match(URL_VALIDATION_REGEX)) return message || 'You must enter full valid URL';
  },
  name: (message?: string) => (form: { [k: string]: any }, key: string) => {
    let field = form[key].trim();
    if (!field) return 'This field is required';
    if (field.length < 3 || field.length > 30) return message || 'Field must be between 3 and 30 characters long';
    if (field && !field.match(NAME_VALIDATION_REGEX)) return message || 'No special characters are allowed';
  },
  username: (message?: string) => (form: { [k: string]: any }, key: string) => {
    let field = form[key].trim();
    if (!field) return 'This field is required';
    if (field.length < 3 || field.length > 30) return message || 'Field must be between 3 and 30 characters long';
    if (field && !field.match(USERNAME_VALIDATION_REGEX))
      return message || 'No special characters or white spaces are allowed';
  },
  fileImage: (message?: string, allowedTypes?: string[]) => (form: { [k: string]: any }, key: 'file') => {
    if (!form?.[key]) return message || 'This field is required';
    const checkTypes = allowedTypes || allowedImageTypes;
    if (!checkTypes.includes(form?.[key]?.type as string))
      return 'You must provide a valid image (jpg, png, webp) file';
  },
  nonEmptyList: (message?: string) => (_form: { [k: string]: any }, _key: string, field: string[]) => {
    if (!field || (field && !field.length)) return message || 'This field is required';
  },
};

export const combineValidations = (validations: ValidationFunction[]) => {
  return (form: { [k: string]: any }, key: string, field?: any): string | undefined => {
    for (const validate of validations) {
      const message = validate(form, key, field);
      if (message && typeof message === 'string') {
        return message;
      }
    }
    return undefined;
  };
};
