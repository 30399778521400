import { useState } from 'react';
import { UserData } from '../../global/interfaces';
import Icon from '../Icon/Icon';
import Modal from '../modal/modal';
import GameActionModal from '../modal/game-action-modal/game-action-modal';
import GameAuthorizationNotification from './game-authorization-notification/game-authorization-notification';
import './notifications.scss';

interface Props {
  user: UserData | undefined;
}

const Notifications: React.FC<Props> = ({ user }) => {
  const [gameAuthorizedNotificationNumber, setGameAuthorizedNotificationNumber] = useState<number>(0);
  const [selectedGameForAction, setSelectedGameForAction] = useState<{ authId: string; gameId: string } | undefined>();
  const notificationsCounter = gameAuthorizedNotificationNumber;

  return (
    <>
      <div className="notifications_container">
        <Icon name="bell" size={24} />
        {notificationsCounter ? (
          <div className="notifications_container__icon_counter">
            <p>{notificationsCounter}</p>
          </div>
        ) : null}
        <div className="notifications_container__dropdown">
          {notificationsCounter ? <h2>Notifications</h2> : null}
          <GameAuthorizationNotification
            userId={user?.userId}
            setGameAuthorizedNotificationNumber={setGameAuthorizedNotificationNumber}
            setSelectedGameForAction={setSelectedGameForAction}
          />
        </div>
      </div>
      <Modal isOpen={!!selectedGameForAction}>
        <GameActionModal
          authId={selectedGameForAction?.authId as string}
          gameId={selectedGameForAction?.gameId as string}
          setSelectedGameForAction={setSelectedGameForAction}
        />
      </Modal>
    </>
  );
};

export default Notifications;
